const URL = `https://px.ads.linkedin.com/collect/?pid=1399636&conversionId=`;

export const linkedInEvents = {
  workWith: `1251628`,
  formSubmit: `1251612`,
  dovetailHome: `1251620`,
  emailLink: `1251636`,
};

export default function(event) {
  if (window.location.hostname === 'palomagroup.com') {
    const img = new Image();
    img.src = `${URL}${event}&fmt=gif`;
  }
}
