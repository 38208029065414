import React from 'react';
import { useInView } from 'react-intersection-observer';

import PurchasingCodes from '../../images/07-partnership/purchasing-codes.inline.svg';
import GraphMerchant from '../../images/07-partnership/graph-merchant.inline.svg';

export default () => {
  const [ref1, inView1] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const [ref2, inView2] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <div>
      <div className="px-6 sm:px-12 md:px-20 lg:pl-40 pb-16">
        <div className="max-w-2xl mx-auto">
          <div className="flex flex-col lg:flex-row">
            <div className="w-full lg:w-1/2 pr-16 mb-48">
              <div className="mb-16">
                <div className="stat-heading-text text-special-red">Monthly In-Store</div>
                <div className="highlight-text text-navy">Purchasing Codes</div>
              </div>
              <div className="relative mx-auto" style={{ maxWidth: 300 }}>
                <div
                  ref={ref1}
                  className={`animated ${inView1 ? 'fadeIn' : 'fadeOut'}`}
                >
                  <PurchasingCodes className="block" />
                </div>
                <div className="absolute pin-t pin-r -mr-12 mt-56">
                  <div className="big-stat-text text-5xl text-special-red">1.7M</div>
                  <div
                    className="stat-heading-text pl-2 text-navy"
                    style={{ letterSpacing: 5 }}
                  >
                    Sep 2019
                  </div>
                </div>
                <div className="absolute pin-b pin-l -ml-4 lg:-ml-12 -mb-24">
                  <div className="big-stat-text text-5xl text-neon-lavender">100-200</div>
                  <div
                    className="stat-heading-text pl-2 text-navy"
                    style={{ letterSpacing: 5 }}
                  >
                    Feb 2017
                  </div>
                </div>
              </div>
            </div>

            <div className="relative w-full lg:w-1/2">
              <div className="lg:text-right mb-16 lg:mb-32">
                <div className="stat-heading-text text-special-red">Online & In-Store</div>
                <div className="highlight-text text-navy">Afterpay Merchants</div>
              </div>
              <div ref={ref2} className={`animated ${inView2 ? 'fadeIn' : 'fadeOut'}`}>
                <GraphMerchant width="100%" height="100%" className="block -ml-3 lg:ml-0" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
