import React from 'react';
import { useInView } from 'react-intersection-observer';

import NeonLavenderCylinder from '../../images/07-partnership/neon-lavender-cylinder.inline.svg';
import SpecialRedCylinder from '../../images/07-partnership/special-red-cylinder.inline.svg';

import ScrollTracker from '../scroll-tracker';

import Counter from '../counter';
import isMobile from '../../utils/is-mobile';

export default () => {
  const [refMay, inViewMay] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const [refMar, inViewMar] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <ScrollTracker>
      {({ rect: { top, height } }) => {
        const mobile = isMobile();
        const offsetY = mobile ? 0 : top < height && top > -height ? top : 0;

        return (
          <div>
            <div className="mt-32 px-6 sm:px-12 md:px-20 lg:px-40 xl:px-48">
              <div className="max-w-2xl mx-auto">
                <div>
                  <div className="mb-20">
                    <div className="stat-heading-text text-special-red">Global Monthly</div>
                    <div className="highlight-text text-navy">Merchant Referrals</div>
                  </div>
                  <div className="flex items-center flex-col lg:flex-row items-center">
                    <div ref={refMar} className="flex items-center">
                      <div
                        ref={refMay}
                        className="relative z-10"
                        style={{
                          transform: `scaleY(${inViewMay ? 1 : 0})`,
                          transition: 'transform 1s',
                          transformOrigin: 'bottom center',
                        }}
                      >
                        <NeonLavenderCylinder
                          className={`block -mr-10 animated ${
                            inViewMay ? 'fadeIn' : 'fadeOut'
                          }`}
                        />
                      </div>
                      <div
                        style={{
                          transform: `scaleY(${inViewMar ? 1 : 0})`,
                          transition: 'transform 1s',
                          transformOrigin: 'bottom center',
                        }}
                      >
                        <SpecialRedCylinder
                          className={`block animated ${
                            inViewMar ? 'fadeIn' : 'fadeOut'
                          }`}
                        />
                      </div>
                    </div>
                    <div className="flex-grow -mt-32 lg:mt-0 lg:pl-6 xl:pl-12">
                      <div className="my-10">
                        <div
                          className={`big-stat-text text-neon-lavender mb-2 animated ${
                            inViewMay ? 'fadeIn' : 'fadeOut'
                          }`}
                        >
                          <Counter inView={inViewMay} b={62000} />
                        </div>
                        <div
                          className={`date-text animated text-navy ${
                            inViewMay ? 'fadeIn' : 'fadeOut'
                          }`}
                        >
                          May 2017
                        </div>
                      </div>
                      <div className="my-10">
                        <div
                          className={`big-stat-text text-special-red mb-2 animated ${
                            inViewMar ? 'fadeIn' : 'fadeOut'
                          }`}
                        >
                          <Counter inView={inViewMar} b={10000000} />
                        </div>
                        <div
                          className={`date-text animated text-navy ${
                            inViewMar ? 'fadeIn' : 'fadeOut'
                          }`}
                        >
                          Sep 2019
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <svg
              className="block mx-auto"
              width="256"
              height="236"
              viewBox="0 0 256 236"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g transform="translate(0 100)">
                <path
                  d="M242.461 117.326L202.936 137.271L205.425 93.0683L242.461 117.326Z"
                  stroke="#C99CEF"
                  strokeWidth="2"
                  transform={`translate(0 ${offsetY / 6})`}
                />
                <path
                  d="M22.7096 81.5022L5.16184 82.744L12.8603 66.9263L22.7096 81.5022Z"
                  fill="#FF5A7D"
                  transform={`translate(0 ${offsetY / 10})`}
                />
                <path
                  d="M91.2648 148.344L94.0531 130.974L107.701 142.074L91.2648 148.344Z"
                  fill="#143673"
                  transform={`translate(0 ${offsetY / 12})`}
                />
                <path
                  d="M145.178 11.2745L171.133 17.174L153.046 36.7021L145.178 11.2745Z"
                  fill="#B1F8FC"
                  transform={`translate(0 ${offsetY / 8})`}
                />
              </g>
            </svg>
          </div>
        );
      }}
    </ScrollTracker>
  );
};
