import React from 'react';

export default ({ textFragments }) => (
  <div
    className="stat-heading-text flex flex-col self-center leading-normal text-navy"
    style={{ letterSpacing: 6 }}
  >
    {textFragments.map(tf => (
      <span key={tf} className="bg-turquoise self-start px-1">{tf}</span>
    ))}
  </div>
);
