/* global ga */

import React from 'react';
import { useInView } from 'react-intersection-observer';

import loadLinkedinImage, {
  linkedInEvents,
} from '../../utils/load-linkedin-image';

export default () => {
  const [ref1, inView1] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <div className="px-6 sm:px-12 md:px-20 lg:px-40 xl:px-48">
      <div className="max-w-xl mx-auto">
        <div className="mt-32 mb-32">
          <blockquote ref={ref1} className="text-navy">
            <p
              className={`highlight-text animated ${
                inView1 ? 'fadeInUpSmall' : 'fadeOutUpSmall'
              }`}
            >
              <span className="bg-turquoise">
                &ldquo;Paloma have done an excellent job of supporting
                Afterpay with creativity, agility and technical proficiency. I
                would enthusiastically recommend Paloma to any business
                looking for a genuine partner to work with.&rdquo;
              </span>
            </p>
            <cite
              className={`body-text animated delay-500ms ${
                inView1 ? 'fadeIn' : 'fadeOut'
              }`}
            >
              Nick Molnar, Founder of Afterpay
            </cite>
          </blockquote>
        </div>
        <div className="relative z-50 mb-16">
          <div className="my-6">
            <a
              className="highlight-text text-navy bg-neon-lavender no-underline hover:bg-turquoise"
              target="_blank"
              rel="noopener noreferrer"
              href="https://afterpay.com"
            >
              Afterpay.com
            </a>
          </div>
          <div className="my-6">
            <a
              className="highlight-text text-navy bg-neon-lavender no-underline hover:bg-turquoise"
              target="_blank"
              rel="noopener noreferrer"
              href="https://palomagroup.com"
            >
              palomagroup.com
            </a>
          </div>
          <div className="text-navy my-16">
            <div className="my-5">
              Have a project in mind? Feel free to send us an email:
            </div>
            <a
              className="text-special-red"
              href="mailto:hello@palomagroup.com"
              onClick={() => {
                if (typeof ga !== 'undefined')
                  ga(
                    'send',
                    'event',
                    'link',
                    'click',
                    'afterpay_story_start_a_project'
                  );

                loadLinkedinImage(linkedInEvents.emailLink);
              }}
            >
              hello@palomagroup.com
            </a>
          </div>
          <div className="mt-32 border-t border-ash-black">
            <p className="body-text text-xs">
              * All data and figures sourced from Afterpay Touch public filings. Figures current at time of Afterpay acquisition by Block.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
