import React from 'react';
import { useInView } from 'react-intersection-observer';

import StatTitle from './stat-title';
import Star from '../../images/07-partnership/star.inline.svg';
import Avatar from '../../images/07-partnership/avatar.inline.svg';

export default () => {
  const [ref1, inView1] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const [ref2, inView2] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const [ref3, inView3] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <div className="md:ml-16 lg:ml-32 lg:pl-4 pb-16 overflow-hidden">
      <div className="flex flex-col lg:flex-row">
        <div ref={ref1} className={`stat w-full lg:w-1/3 px-8 md:px-16 lg:px-8 xl:px-16 py-8 ${inView1 ? 'in-view' : ''}`}>
          <div className="flex h-32">
            <StatTitle textFragments={['Greater', 'Than']} />
          </div>
          <div className="big-stat-text relative text-special-red mt-5 mb-4">
            <span style={{ fontSize: '.5em' }}>></span>
            10
            <span style={{ fontSize: '.5em' }}>%</span>
          </div>
          <p className="body-text leading-normal text-navy">
            Of all e-commerce transactions in Australia are processed by
            Afterpay
          </p>
          <div className="mt-8">
            <div className="mx-4 h-6 bg-turquoise">
              <div className="h-full bg-special-red" style={{ width: '10%' }} />
            </div>
          </div>
        </div>
        <div ref={ref2} className={`stat w-full lg:w-1/3 px-8 md:px-16 lg:px-8 xl:px-16 py-8 ${inView2 ? 'in-view' : ''}`}>
          <div className="flex h-32">
            <StatTitle
              textFragments={['Highest', 'Rated App in', 'Australia']}
            />
          </div>
          <div className="big-stat-text relative text-neon-lavender mt-5 mb-4">
            4.9
            <span
              className="relative roman inline-block -mt-8 align-top"
              style={{ fontSize: '.5em', marginTop: '-.75em' }}
            >
              &#9733;
            </span>
          </div>
          <p className="body-text leading-normal text-navy">
            #1 rated mobile app out of top 50 apps in Australia
          </p>
          <div className="mt-8">
            <div className="mx-4 flex justify-between">
              <div>
                <Star height={24} className="block text-neon-lavender" />
              </div>
              <div>
                <Star height={24} className="block text-neon-lavender" />
              </div>
              <div>
                <Star height={24} className="block text-neon-lavender" />
              </div>
              <div>
                <Star height={24} className="block text-neon-lavender" />
              </div>
              <div className="relative">
                <Star height={24} className="block text-turquoise" />
                <div
                  className="absolute pin-t overflow-hidden"
                  style={{ width: '65%' }}
                >
                  <Star height={24} className="block text-neon-lavender" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ref={ref3} className={`stat w-full lg:w-1/3 px-8 md:px-16 lg:px-8 xl:px-16 py-8 ${inView3 ? 'in-view' : ''}`}>
          <div className="flex h-32">
            <StatTitle textFragments={['Australian', 'Millennials']} />
          </div>
          <div className="big-stat-text relative text-navy mt-5 mb-4">
            27
            <span style={{ fontSize: '.5em' }}>%</span>
          </div>
          <p className="body-text leading-normal text-navy">
            Of all Australian millennials are Afterpay customers
          </p>
          <div className="mt-8">
            <div className="mx-4 flex justify-between">
              <Avatar width="100%" height={24} className="text-navy" />
              <Avatar width="100%" height={24} className="text-navy" />
              <div className="relative">
                <Avatar height={24} className="block text-turquoise" />
                <div
                  className="absolute pin-t overflow-hidden"
                  style={{ width: '70%' }}
                >
                  <Avatar height={24} className="block text-navy" />
                </div>
              </div>
              <Avatar width="100%" height={24} className="text-turquoise" />
              <Avatar width="100%" height={24} className="text-turquoise" />
              <Avatar width="100%" height={24} className="text-turquoise" />
              <Avatar width="100%" height={24} className="text-turquoise" />
              <Avatar width="100%" height={24} className="text-turquoise" />
              <Avatar width="100%" height={24} className="text-turquoise" />
              <Avatar width="100%" height={24} className="text-turquoise" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
