import React from 'react';
import { useInView } from 'react-intersection-observer';

export default () => {
  const [ref1, inView1] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <div className="flex flex-col lg:flex-row">
      <div className="w-full lg:w-3/4 pt-32 md:pt-64 pb-16 px-8 sm:px-12 md:px-32 lg:pl-56 xl:pl-64 lg:pr-8 xl:pr-16">
        <p
          ref={ref1}
          className={`body-text text-navy mb-12 animated ${
            inView1 ? 'fadeIn' : 'fadeOut'
          }`}
        >
          In a partnership spanning three years, Afterpay and Paloma
          have built the fastest growing consumer technology platform in
          Australian history. With over 5.2 million monthly users transacting $7.2
          billion annually across four international markets, Afterpay shows no signs of
          slowing down.
        </p>
        <p
          className={`body-text text-navy animated delay-500ms ${
            inView1 ? 'fadeIn' : 'fadeOut'
          }`}
        >
          With all of this success to date, Paloma and Afterpay remain in a
          close strategic partnership.
        </p>
      </div>
    </div>
  );
};
