import React from 'react';
import { useInView } from 'react-intersection-observer';

import StatTitle from './stat-title';
import RequestIcon from '../../images/07-partnership/requests-icon.inline.svg';
import DownloadsIcon from '../../images/07-partnership/downloads-icon.inline.svg';
import UptimeIcon from '../../images/07-partnership/uptime-icon.inline.svg';

export default () => {
  const [ref1, inView1] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const [ref2, inView2] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const [ref3, inView3] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <div
      className="md:ml-16 lg:ml-32 lg:pl-4 overflow-hidden"
    >
      <div className="flex flex-col lg:flex-row">
        <div ref={ref1} className={`stat w-full lg:w-1/3 px-8 md:px-16 lg:px-8 xl:px-16 py-8 bg-special-red ${inView1 ? 'in-view' : ''}`}>
          <div className="flex h-32">
            <StatTitle
              textFragments={['Weekly', 'requests to', 'Afterpay', 'servers']}
            />
          </div>
          <div className="big-stat-text relative text-white mt-5 mb-4">
            700
            <span style={{ fontSize: '.5em' }}>M</span>
          </div>
          <p className="w-5/6 body-text leading-normal text-white">
            Weekly requests served  by the global Afterpay consumer platform
          </p>
          <div>
            <RequestIcon className="block ml-auto" />
          </div>
        </div>
        <div ref={ref2} className={`stat w-full lg:w-1/3 px-8 md:px-16 lg:px-8 xl:px-16 py-8 bg-neon-lavender ${inView2 ? 'in-view' : ''}`}>
          <div className="flex h-32">
            <StatTitle textFragments={['Mobile', 'Downloads']} />
          </div>
          <div className="big-stat-text relative text-white mt-5 mb-4">
            6.3
            <span style={{ fontSize: '.5em' }}>M</span>
          </div>
          <p className="w-5/6 body-text leading-normal text-white">
            iOS and Android app downloads globally
          </p>
          <div>
            <DownloadsIcon className="block ml-auto" />
          </div>
        </div>
        <div ref={ref3} className={`stat w-full lg:w-1/3 px-8 md:px-16 lg:px-8 xl:px-16 py-8 bg-navy ${inView3 ? 'in-view' : ''}`}>
          <div className="flex h-32">
            <StatTitle textFragments={['Average', 'Uptime']} />
          </div>
          <div className="big-stat-text relative text-white mt-5 mb-4">
            99
            <span style={{ fontSize: '.5em' }}>.999%</span>
          </div>
          <p className="w-5/6 body-text leading-normal text-white">
            Availability of the Afterpay consumer platform
          </p>
          <div className="mt-8">
            <UptimeIcon width="100%" className="block ml-auto" />
          </div>
        </div>
      </div>
    </div>
  );
};
