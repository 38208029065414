import React from 'react';

import HeroText from '../../components/hero-text';
import HighlightTextHeading from '../../components/highlight-text-heading';

import ScrollTracker from '../scroll-tracker';
import isMobile from '../../utils/is-mobile';
import LazyLoadWrap from '../lazy-load-wrap';

const hero = () => {
  return (
    <ScrollTracker>
      {({ rect: { top, height } }) => {
        const mobile = isMobile();
        const offsetY = mobile
          ? 0
          : top < height && top > -height
          ? top
          : 0;

        let topProgress = (height - top * 2) / height;

        topProgress = topProgress >= 1 ? 1 : topProgress || 1;

        return (
          <div className="hero">
            <div className="bg">
              <div
                className="absolute w-1/2 h-screen"
                style={{
                  left: '50%',
                  opacity: topProgress,
                }}
              >
                <svg
                  width="100%"
                  height="100%"
                  viewBox="0 0 653 1015"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g transform={`translate(0 ${-offsetY})`}>
                    <path
                      d="M326.524 572.671L69.6026 395.981C42.0785 377.076 25.6172 345.802 25.6172 312.407V38.6262C25.6172 27.6714 37.919 21.2222 46.9462 27.4064L442.904 299.155C462.286 312.407 452.816 342.709 429.363 342.709H223.596C200.143 342.709 190.673 312.407 210.055 299.155L606.101 27.4064C615.128 21.2222 627.43 27.6714 627.43 38.6262V312.407C627.43 345.802 610.969 376.987 583.445 395.981L326.524 572.671Z"
                      stroke="#C99CEF"
                      strokeWidth="48"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                    />
                    <path
                      d="M326.524 572.671L69.6026 395.981C42.0785 377.076 25.6172 345.802 25.6172 312.407V38.6262C25.6172 27.6714 37.919 21.2222 46.9462 27.4064L442.904 299.155C462.286 312.407 452.816 342.709 429.363 342.709H223.596C200.143 342.709 190.673 312.407 210.055 299.155L606.101 27.4064C615.128 21.2222 627.43 27.6714 627.43 38.6262V312.407C627.43 345.802 610.969 376.987 583.445 395.981L326.524 572.671Z"
                      stroke="url(#dt_ap_paint0_linear)"
                      strokeWidth="48"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                    />
                  </g>
                  <g transform={`translate(0 ${offsetY})`}>
                    <path
                      d="M263.299 990.213C263.376 947.808 263.453 864.695 263.453 864.695C263.453 864.695 245.589 864.541 221.383 864.541C196.713 864.541 191.686 856.06 203.828 835.397C239.247 775.105 275.054 715.045 311.247 655.216C321.996 637.483 331.508 635.864 343.263 655.678C378.992 715.816 414.799 775.876 450.064 836.322C461.355 855.597 456.25 864.078 433.514 864.772C410.081 864.772 390.051 864.772 390.051 864.772V990.675C390.051 990.675 485.638 990.675 541.474 990.675C606.822 989.75 645.181 932.08 620.511 873.176C617.804 866.622 614.556 860.223 610.921 854.055C543.871 740.334 476.744 626.689 409.231 513.353C402.425 501.865 394.305 490.377 384.329 481.511C344.655 445.968 282.014 454.449 254.25 500.477C181.091 621.446 108.473 742.801 37.0152 864.849C4.45699 920.438 41.578 985.818 106.153 989.75C127.188 990.907 216.975 990.213 263.299 990.213Z"
                      stroke="#C99CEF"
                      strokeWidth="48"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                    />
                    <path
                      d="M263.299 990.213C263.376 947.808 263.453 864.695 263.453 864.695C263.453 864.695 245.589 864.541 221.383 864.541C196.713 864.541 191.686 856.06 203.828 835.397C239.247 775.105 275.054 715.045 311.247 655.216C321.996 637.483 331.508 635.864 343.263 655.678C378.992 715.816 414.799 775.876 450.064 836.322C461.355 855.597 456.25 864.078 433.514 864.772C410.081 864.772 390.051 864.772 390.051 864.772V990.675C390.051 990.675 485.638 990.675 541.474 990.675C606.822 989.75 645.181 932.08 620.511 873.176C617.804 866.622 614.556 860.223 610.921 854.055C543.871 740.334 476.744 626.689 409.231 513.353C402.425 501.865 394.305 490.377 384.329 481.511C344.655 445.968 282.014 454.449 254.25 500.477C181.091 621.446 108.473 742.801 37.0152 864.849C4.45699 920.438 41.578 985.818 106.153 989.75C127.188 990.907 216.975 990.213 263.299 990.213Z"
                      stroke="url(#dt_ap_paint1_linear)"
                      strokeWidth="48"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="dt_ap_paint0_linear"
                      x1="326.524"
                      y1="25"
                      x2="326.524"
                      y2="493.636"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#FF5A7D" />
                      <stop offset="1" stopColor="#C99CEF" />
                    </linearGradient>
                    <linearGradient
                      id="dt_ap_paint1_linear"
                      x1="326.524"
                      y1="561.47"
                      x2="326.524"
                      y2="990.675"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#C99CEF" />
                      <stop offset="1" stopColor="#FF5A7D" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
            <div className="hero-text-container">
              <HeroText date="April 2019" lineA="Paloma" lineB="+ Afterpay" />
              <HighlightTextHeading
                bgColor="turquoise"
                textColor="navy"
                lineColor="neon-lavender"
              >
                A long term partnership
              </HighlightTextHeading>
            </div>
            {!mobile && (
              <div
                className="absolute z-10"
                style={{ width: '30vw', right: '12vw', top: '12vh' }}
              >
                <svg
                  width="100%"
                  height="339"
                  viewBox="0 0 408 339"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M297.328 235.691L341.104 203.809L346.676 257.869L297.328 235.691Z"
                    fill="#B1F8FC"
                    transform={`translate(0 ${offsetY / 12})`}
                  />
                  <path
                    d="M115.919 58.9352L205.655 108.877L117.772 162.046L115.919 58.9352Z"
                    stroke="white"
                    strokeWidth="2"
                    transform={`translate(0 ${offsetY / 6})`}
                  />
                  <path
                    d="M321.858 321.095L311.199 320.561L316.989 311.547L321.858 321.095Z"
                    stroke="#143673"
                    strokeWidth="2"
                    transform={`translate(0 ${offsetY / 16})`}
                  />
                  <path
                    d="M16.6525 212.488L52.6982 216.856L30.9137 246.06L16.6525 212.488Z"
                    fill="#143673"
                    transform={`translate(0 ${offsetY / 14})`}
                  />
                  <path
                    d="M202.329 277.682L200.703 267.574L210.222 271.212L202.329 277.682Z"
                    fill="#C99CEF"
                    transform={`translate(0 ${offsetY / 20})`}
                  />
                </svg>
              </div>
            )}
          </div>
        );
      }}
    </ScrollTracker>
  );
};

export default LazyLoadWrap(hero);
