import React from 'react';

import Hero from '../../components/07-partnership/hero';
import Intro from '../../components/07-partnership/intro';
import PlatformUsers from '../../components/07-partnership/platform-users';
import Referrals from '../../components/07-partnership/referrals';
import PurchasingMerchants from '../../components/07-partnership/purchasing-merchants';
import StatsColorful from '../../components/07-partnership/stats-colorful';
import StatsPlain from '../../components/07-partnership/stats-plain';
import Quotes from '../../components/07-partnership/quotes';

export default ({ id, className, forwardRef }) => {
  return (
    <div id={id} className={className} ref={forwardRef}>
      <div className="nav-text">
        <div>07. A Long Term Partnership</div>
      </div>
      <Hero />
      <Intro />
      <PlatformUsers />
      <Referrals />
      <PurchasingMerchants />
      <StatsColorful />
      <StatsPlain />
      <Quotes />
    </div>
  );
};
