import React from 'react';
import { useInView } from 'react-intersection-observer';

import NeonLavenderBlock from '../../images/07-partnership/neon-lavender-block.inline.svg';
import TurquoiseBlock from '../../images/07-partnership/turquoise-block.inline.svg';

export default () => {
  const [refMobile, inViewMobile] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const [refWeb, inViewWeb] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <div className="relative">
      <div
        className="absolute w-3/4"
        style={{
          height: '79%',
          background: 'linear-gradient(180deg, #FF5A7D 36%, #C99CEF 128%)',
        }}
      />
      <div className="relative z-10">
        <div className="mb-12 pt-24 xl:pt-48 px-6 sm:px-12 md:px-20 lg:px-40 xl:px-48">
          <div className="stat-heading-text text-white">Global Monthly</div>
          <div className="highlight-text text-white">Users by Platform</div>
        </div>
        <div
          ref={refMobile}
          className="my-12 px-6 sm:px-12 md:px-20 lg:px-40 xl:px-48"
        >
          <div className="relative max-w-2xl">
            <div style={{ maxWidth: 369 }}>
              <NeonLavenderBlock
                width="100%"
                height="100%"
                className={`block animated ${
                  inViewMobile ? 'fadeInUpSmall' : 'fadeOutUpSmall'
                }`}
              />
            </div>
            <div className="absolute pin-t pin-l mt-12 xl:mt-20 sm:ml-16 xl:ml-20">
              <div
                className={`date-text text-navy animated ${
                  inViewMobile ? 'fadeIn' : 'fadeOut'
                }`}
              >
                Mobile
              </div>
              <div
                className={`big-stat-text text-navy animated delay-500ms ${
                  inViewMobile ? 'fadeIn' : 'fadeOut'
                }`}
              >
                2.4 Million
              </div>
            </div>
          </div>
        </div>
        <div
          ref={refWeb}
          className="my-12 px-6 sm:px-12 md:px-20 lg:px-40 xl:px-48"
        >
          <div className="relative max-w-2xl ml-auto">
            <div className="ml-auto" style={{ maxWidth: 369 }}>
              <TurquoiseBlock
                width="100%"
                height="100%"
                className={`block ml-auto animated ${
                  inViewWeb ? 'fadeInUpSmall' : 'fadeOutUpSmall'
                }`}
              />
            </div>
            <div className="absolute pin-t pin-r mt-12 xl:mt-20 sm:mr-16 xl:mr-20">
              <div
                className={`date-text text-navy text-right animated ${
                  inViewWeb ? 'fadeIn' : 'fadeOut'
                }`}
              >
                Web
              </div>
              <div
                className={`big-stat-text text-navy text-right animated delay-500ms ${
                  inViewWeb ? 'fadeIn' : 'fadeOut'
                }`}
              >
                2.3 Million
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
