import React from 'react';
import { useSpring, animated, config } from 'react-spring';

import numberWithCommas from '../utils/number-with-commas';

export default ({
  inView = 0,
  a = 0,
  b,
  duration = 1000,
  delay = 0,
  interpolate = val => numberWithCommas(parseInt(val)),
}) => {
  const props = useSpring({
    number: inView ? b : a,
    from: { number: a },
    delay,
    config: {
      ...config,
      duration,
    },
  });

  return <animated.span>{props.number.interpolate(interpolate)}</animated.span>;
};
